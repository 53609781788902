@tailwind base;
@tailwind components;
@tailwind utilities;


.loader-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
  z-index:100;
}
.loader-container[disabled]{
  display: none;
}
.loader{
  left: calc(50% - 64px);
  top: calc(30% - 64px);
  z-index: 1000;
  position: absolute;
}
